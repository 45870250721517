import { getAuthUser } from '#imports';

export default defineNuxtRouteMiddleware((from, to) => {
  const authUser = getAuthUser();
  logger().debug('[middleware] auth', { from: from.fullPath, to: to.fullPath, authUserId: authUser?.id });
  if (!authUser) {
    const query: Record<string, any> = {};
    if (to.path.indexOf('/auth/') !== 0) {
      query.redirect = to.fullPath;
    }
    return navigateTo({
      path: '/auth/login',
      query,
    });
  }
});
